import * as Yup from 'yup';
export var validationSchema = Yup.object().shape({
    
    gender: Yup.string()
        .required('Gender is required'),
    firstName: Yup.string()
        .required('First Name is required')
        .min(2, 'Name must be at least 2 characters'),
    lastName: Yup.string()
        .required('Last name is required')
        .min(2, 'Name must be at least 2 characters'),
    // dob: Yup.string()
    //     .required('Date of Birth is required')
    //     .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Date of Birth must be a valid date in the format YYYY-MM-DD'),
    // email: Yup.string()
    //     .required('Email is required')
    //     .email('Email is invalid'),
    mobile: Yup.string()
        .required('Mobile is required'),
    residence: Yup.string()
        .required('Residence is required')          
    // password: Yup.string()
    //     .min(6, 'Password must be at least 6 characters')
    //     .required('Password is required'),
    // confirmPassword: Yup.string()
    //     .oneOf([Yup.ref('password'), null], 'Passwords must match')
    //     .required('Confirm Password is required'),
    // acceptTerms: Yup.bool()
    //     .oneOf([true], 'Accept Ts & Cs is required')
});