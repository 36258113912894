import * as Yup from 'yup';
export var validationSchema = Yup.object().shape({
    
    accountName: Yup.string()
        .required('Account Name is required')
        .min(2, 'Name must be at least 2 characters'),
        accountType: Yup.string()
        .required('Type is required')
        .min(2, 'Type must be at least 2 characters'),
    // dob: Yup.string()
    //     .required('Date of Birth is required')
    //     .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Date of Birth must be a valid date in the format YYYY-MM-DD'),
    parentId: Yup.string()
        .required('parentId is required')
});