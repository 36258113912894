import { ceil } from "lodash-es";
import React, { Fragment } from "react";
// import Breadcrumb from '../common/breadcrumb';
import { Pagination, PaginationItem, PaginationLink, Alert } from "reactstrap";
// import {Pagi_Nations,PaginationWithIcons,PaginationAlignment,PaginationActiveDisabled,PaginationColor,PaginationSizing,Previous,Next} from '../../constant'

const PaginationComponent = (props) => {
  return (
    <Fragment>
      <div className="col-xl-5"> </div>
      <div className="col-xl-4">
        <div className="card pull-right">
          <div className="card-body">
            <Pagination
              aria-label="Page navigation example"
              className="pagination-primary"
            >
              <PaginationItem disabled={props.activePage === 1 ? true : false}>
                <PaginationLink first onClick={() => props.onChange(1)} />
              </PaginationItem>
              <PaginationItem disabled={props.activePage === 1 ? true : false}>
                <PaginationLink
                  previous
                  onClick={() => props.onChange(props.activePage - 1)}
                />
              </PaginationItem>
              <PaginationItem active>
                <PaginationLink>{props.activePage}</PaginationLink>
              </PaginationItem>

              <PaginationItem
                disabled={
                  props.activePage ===
                  Math.ceil(props.totalItemsCount / props.itemsCountPerPage)
                    ? true
                    : false
                }
              >
                <PaginationLink
                  next
                  onClick={() => props.onChange(props.activePage + 1)}
                />
              </PaginationItem>
              <PaginationItem
                disabled={
                  props.activePage ===
                  Math.ceil(props.totalItemsCount / props.itemsCountPerPage)
                    ? true
                    : false
                }
              >
                <PaginationLink
                  last
                  onClick={() =>
                    props.onChange(
                      Math.ceil(props.totalItemsCount / props.itemsCountPerPage)
                    )
                  }
                />
              </PaginationItem>
              <PaginationItem>
                &nbsp; &nbsp; showing page {props.activePage} of{" "}
                {Math.ceil(props.totalItemsCount / props.itemsCountPerPage)}
              </PaginationItem>
            </Pagination>
          </div>
        </div>
      </div>
      <div className="col-xl-2"></div>
    </Fragment>
  );
};
export default PaginationComponent;
