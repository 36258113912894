import React, { Fragment, useState, useEffect } from "react";
import CountUp from "react-countup";
import Breadcrumb from "../common/breadcrumb";
import { Navigation, Box, MessageSquare, Users, Anchor } from "react-feather";
import jwt_decode from "jwt-decode";

import {
  UniversityEarning,
  Monthly,
  Yearly,
  Average,
  Maximum,
  Minimum,
  Balance,
} from "../../constant";

import ChartistGraph from "react-chartist";
import {
  attendanceData,
  mpesaGraphOptions,
  earningOptions,
} from "./default_data";
import Loader from "../common/loader";

import ContentLoader, { Facebook, BulletList } from "react-content-loader";

import axios from "../../services/axios";

const Default = () => {
  const [data, setData] = useState([]);
  const [sms_units, setUnits] = useState(0);
  const [sms_sender, setSmsSender] = useState("N/A");
  const [contacts, setContacts] = useState(0);
  const [contacts_list, setContactList] = useState([]);
  const [outbox, setOutbox] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [mpesa_numbers, setMpesa] = useState();
  const [income_numbers, setIncomeNumbers] = useState();

  const [loading, setLoading] = useState(false);

  const datasetKeyProvider = () => {
    return Math.random();
  };
  const [date, setDate] = useState({
    date: new Date(),
  });
  const [startDate, setStartDate] = useState(new Date());
  const [generalData, setGeneralData] = useState([]);
  const { clients, cartProducts, employeeStatus } = generalData;

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    let params = {};
    // let currUser = JSON.parse(localStorage.getItem('c360-user'));
    let currUser = JSON.parse(localStorage.getItem("c360-user"));

    if (!currUser || !currUser.user || !currUser.access_token) {
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    // let token = localStorage.getItem(TOKEN);
    let decodedToken = jwt_decode(currUser.access_token);
    console.log("Decoded Token", decodedToken);
    let currentDate = new Date();

    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    let response = await axios.get(
      "/partner-home?tenantid=" + currUser.user.Partner.identifier,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: currUser.access_token,
        },
      }
    );
    console.log(response.data);
    setLoading(false);
    setData(response.data.data);
    setNotifications(response.data.data.notification_list);
    setOutbox(response.data.data.nofification_this_week);
    setIncomeNumbers(response.data.data.transactions_this_week);
    setContacts(response.data.data.contacts);
    setContactList(response.data.data.contact_list);
    setMpesa(
      dataAnalysis(
        response.data.data.mpesa_numbers,
        response.data.data.cash_numbers
      )
    );
    // setCash(cashAnalysis(response.data.data.cash_numbers));
    response.data.data.settings.map((ele, index) => {
      if (ele.name === "Sms Units") setUnits(ele.value);
      if (ele.name === "Sms Sender Name") setSmsSender(ele.value);
    });
  };
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dataAnalysis = (mpesa, cash) => {
    let graph_data = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      series: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    };
    let mpesa_last_12_months = mpesa.slice(-12);
    let cash_last_12_months = cash.slice(-12);
    console.log(mpesa_last_12_months);
    let last_month = 1;
    let last_year = 1;
    mpesa_last_12_months.map((d, idx) => {
      graph_data.labels[idx] = month[d.mn - 1];
      graph_data.series[0][idx] = d.amount || 0;
      graph_data.series[1][idx] = cash_last_12_months[idx]?.amount || 0;
      last_month = d.mn;
      last_year = d.yr;
    });
    if (mpesa_last_12_months.length < 12) {
      let count = mpesa_last_12_months.length;
      for (let i = count; i < 12; i++) {
        graph_data.labels[i] = month[(last_month + i - 1) % 12];
        graph_data.series[0][i] = 0;
        // if(cash_last_12_months[count]){
        graph_data.series[1][i] = cash_last_12_months[i]?.amount || 0;
        // }
        // else{

        // }
      }
    }
    return graph_data;
  };

  const cashAnalysis = (data) => {
    let graph_data = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      series: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    };
    let mpesa_last_12_months = data.slice(-12);
    mpesa_last_12_months.map((d, idx) => {
      graph_data.labels[idx] = month[d.mn - 1] + " " + d.yr;
      graph_data.series[0][idx] = d.amount;
    });

    return graph_data;
  };

  const handleChange = (date) => {
    setStartDate(date);
  };

  const settings = {
    className: "center",
    centerMode: true,
    speed: 500,
    arrows: false,
    centerPadding: "5px",
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // const month = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

  var d = new Date();
  let dateshow =
    month[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
  return (
    <Fragment>
      <Breadcrumb title="Dashboard" parent="" />
      {loading && (
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <Loader />
            </div>
          </div>
        </div>
      )}
      {data && sms_units && mpesa_numbers ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 col-xl-3 col-lg-6">
              <div className="card o-hidden">
                <div className="bg-success b-r-4 card-body">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Navigation />
                    </div>
                    <div className="media-body">
                      <span className="m-0">SMS Sender</span>
                      <h5 className="mb-0 counter">{sms_sender}</h5>
                      <Navigation className="icon-bg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-3 col-lg-6">
              <div className="card o-hidden">
                <div className="bg-danger b-r-4 card-body">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Anchor />
                    </div>
                    <div className="media-body">
                      <span className="m-0">SMS Units balance</span>
                      <h4 className="mb-0 counter">
                        <CountUp className="counter" end={sms_units} />
                      </h4>
                      <Anchor className="icon-bg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-3 col-lg-6">
              <div className="card o-hidden">
                <div className="bg-secondary b-r-4 card-body">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Users />
                    </div>
                    <div className="media-body">
                      <span className="m-0">All our contacts</span>
                      <h4 className="mb-0 counter">
                        <CountUp className="counter" end={contacts} />
                      </h4>
                      <Users className="icon-bg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-3 col-lg-6">
              <div className="card o-hidden">
                <div className="bg-info b-r-4 card-body">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <MessageSquare />
                    </div>
                    <div className="media-body">
                      <span className="m-0">Messages this week</span>
                      <h4 className="mb-0 counter">
                        <CountUp className="counter" end={outbox} />
                      </h4>
                      <MessageSquare className="icon-bg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-sm-6 col-xl-3 col-lg-6">
              <div className="card o-hidden">
                <div className="bg-success b-r-4 card-body">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Box />
                    </div>
                    <div className="media-body">
                      <span className="m-0">Income this week</span>
                      <h4 className="mb-0 counter">
                        <CountUp className="counter" end={income_numbers} />
                      </h4>
                      <Box className="icon-bg" />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h5>{MemoryUsage}</h5>
                </div>
                <div className="card-body server-canvas">
                    <Line data={buyData} options={buyOption} width={700} height={350}  datasetKeyProvider={datasetKeyProvider}/>
                </div>

              
            </div>
         </div> */}

            <div className="col-xl-12 xl-100">
              <div className="card">
                <div className="card-header">
                  <h5>Recent Contacts</h5>
                </div>
                <div className="card-body">
                  <div className="user-status table-responsive">
                    <table className="table table-bordernone">
                      <thead>
                        <tr>
                          <th scope="col">{"Organisation"}</th>
                          <th scope="col">{"Name"}</th>
                          <th scope="col">{"Mobile"}</th>
                          <th scope="col">{"Date Added"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {contacts_list.map((data) => (
                          <tr key={data.id}>
                            <td>{data.Partner.name}</td>
                            <td className="digits">
                              {data.firstName + " " + data.lastName}
                            </td>
                            <td>{data.phone}</td>
                            <td className="digits">{data.createdAt}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12 xl-100">
              <div className="card">
                <div className="card-header">
                  <h5>Recent Notifications</h5>
                </div>
                <div className="card-body">
                  <div className="user-status table-responsive">
                    <table className="table table-bordernone">
                      <thead>
                        <tr>
                          <th scope="col">{"Organisation"}</th>
                          <th scope="col">{"Recipient"}</th>
                          <th scope="col">{"Message"}</th>
                          <th scope="col">{"Date Sent"}</th>
                          <th scope="col">{"Status"}</th>
                        </tr>
                      </thead>

                      <tbody>
                        {notifications.map((data) => (
                          <tr key={data.id}>
                            <td>{data.Partner.name}</td>
                            <td>{data.recipient}</td>
                            <td>{data.message.substring(0, 25) + ""}</td>
                            <td>{data.sentDate}</td>
                            <td>{data.status === 1 ? "Sent" : "Failed"}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default Default;
