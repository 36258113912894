import React, { Fragment, useState, useEffect } from "react";
import PageHeader from "../../common/pageheader";
import axios from "../../../services/axios";
import Loader from "../../common/loader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SweetAlert from "sweetalert2";
import { useHistory } from "react-router-dom";

import { validationSchema } from "./validation_schema";

const genderItems = [
  { id: "Male", title: "Male" },
  { id: "Female", title: "Female" },
  { id: "Other", title: "Other" },
];

const ContactEdit = (props) => {
  let history = useHistory();
  const recordForEdit = JSON.parse(localStorage.getItem("recordForEdit"));
  const defaults = {
    firstName: recordForEdit.firstName,
    lastName: recordForEdit.lastName,
    mobile: recordForEdit.phone,
    gender: recordForEdit.gender,
    email: recordForEdit.email,
    residence: recordForEdit.residence,
  };

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: defaults,
  };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;
  const currUser = JSON.parse(localStorage.getItem("c360-user"));

  const goBack = () => {
    // window.location.href = `${process.env.PUBLIC_URL}/contacts/default`;
    history.push("/contacts/default");
  };

  const onSubmit = (data) => {
    if (!currUser || !currUser.user || !currUser.access_token) {
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    // const params = {
    //     tenantid: currUser.user.Partner.identifier
    // };
    console.log(data);
    axios
      .put(
        "/member/" +
          recordForEdit.id +
          "?tenantid=" +
          currUser.user.Partner.identifier,
        data
      )
      .then((res) => {
        console.log(res);
        SweetAlert.fire({
          title: "Awesome!",
          text: "New contact saved successfully!",
          icon: "success",
        });

        // window.location.href = `${process.env.PUBLIC_URL}/contacts/default`;
        history.push("/contacts/default");
      })
      .catch((error) => {
        if (error.response) {
          SweetAlert.fire({
            title: "Error!",
            text: error.response.data.status_msg,
            icon: "error",
          });
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          SweetAlert.fire({
            title: "Error!",
            text: "The request was made but no response was received",
            icon: "error",
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          SweetAlert.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
          });
        }
      });

    return false;
  };

  return (
    recordForEdit && (
      <Fragment>
        <PageHeader title="Edit Contact" parent="Contacts" />

        <div className="col-lg-3"></div>
        <div className="col-lg-6">
          <div className="card">
            <div className="card-body">
              <div className="card m-3">
                <div className="card-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-row">
                      <div className="col-3">
                        <label htmlFor="exampleFormControlInput1">
                          FirstName:<span className="font-danger">*</span>
                        </label>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <input
                            name="firstName"
                            type="text"
                            {...register("firstName")}
                            className={`form-control ${
                              errors.firstName ? "is-invalid" : ""
                            }`}
                          />
                          <div className="invalid-feedback">
                            {errors.firstName?.message}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-3">
                        <label htmlFor="exampleFormControlInput1">
                          Last Name:<span className="font-danger">*</span>
                        </label>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <input
                            name="lastName"
                            type="text"
                            {...register("lastName")}
                            className={`form-control ${
                              errors.lastName ? "is-invalid" : ""
                            }`}
                          />
                          <div className="invalid-feedback">
                            {errors.lastName?.message}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-3">
                        <label htmlFor="exampleFormControlInput1">
                          Gender:<span className="font-danger">*</span>
                        </label>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <select
                            name="title"
                            {...register("gender")}
                            className={`form-control ${
                              errors.gender ? "is-invalid" : ""
                            }`}
                          >
                            <option value=""></option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                            <option value="O">Other</option>
                          </select>
                          <div className="invalid-feedback">
                            {errors.gender?.message}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-3">
                        <label htmlFor="exampleFormControlInput1">
                          Mobile:<span className="font-danger">*</span>
                        </label>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <input
                            name="mobile"
                            type="text"
                            {...register("mobile")}
                            className={`form-control ${
                              errors.mobile ? "is-invalid" : ""
                            }`}
                          />
                          <div className="invalid-feedback">
                            {errors.mobile?.message}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="form-row">
                      <div className="col-3">
                        <label htmlFor="exampleFormControlInput1">
                          Email:<span className="font-danger">*</span>
                        </label>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <input
                            name="email"
                            type="text"
                            {...register("email")}
                            className={`form-control ${
                              errors.email ? "is-invalid" : ""
                            }`}
                          />
                          <div className="invalid-feedback">
                            {errors.email?.message}
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div className="form-row">
                      <div className="col-3">
                        <label htmlFor="exampleFormControlInput1">
                          Other Details:<span className="font-danger">*</span>
                        </label>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <input
                            name="residence"
                            type="text"
                            {...register("residence")}
                            className={`form-control ${
                              errors.residence ? "is-invalid" : ""
                            }`}
                            defaultValue={recordForEdit.residence}
                          />
                          <div className="invalid-feedback">
                            {errors.residence?.message}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-9">
                        <div className="form-group  pull-right">
                          <button
                            type="submit"
                            className="btn btn-primary mr-1"
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            onClick={goBack}
                            className="btn btn-danger"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3"></div>
      </Fragment>
    )
  );
};
export default ContactEdit;
