import React, { Fragment, useState, useEffect } from "react";
import { Redirect,useHistory } from "react-router-dom";
import PageHeader from "../../common/pageheader";
import axios from "../../../services/axios";
import Loader from "../../common/loader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import SweetAlert from "sweetalert2";

import { validationSchema } from "./validation_schema";


const GroupTypeForm = (props) => {
  let history = useHistory();
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;
  const currUser = JSON.parse(localStorage.getItem("c360-user"));
  const goBack = () => {
    // window.location.href = `${process.env.PUBLIC_URL}/contacts/grouptypes`;
    history.push("/contacts/grouptypes");
  };
  const onSubmit = (data) => {
    console.log(data);
    if (!currUser || !currUser.user || !currUser.access_token) {
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    // const params = {
    //     tenantid: currUser.user.Partner.identifier
    // };

    axios
      .post("/group_types?tenantid=" + currUser.user.Partner.identifier, data)
      .then((res) => {
        console.log(res);
        SweetAlert.fire({
          title: "Awesome!",
          text: "New group type saved successfully!",
          icon: "success",
        });

        history.push("/contacts/grouptypes");
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          // console.log(error.response.data.status_msg);
          // console.log(error.response.status);
          // console.log(error.response.headers);
          SweetAlert.fire({
            title: "Error!",
            text: error.response.data.status_msg,
            icon: "error",
          });
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          SweetAlert.fire({
            title: "Error!",
            text: "The request was made but no response was received",
            icon: "error",
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          SweetAlert.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
          });
        }
      });

    // console.log('testing');
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
    return false;
  };

  return (
    <Fragment>
      <PageHeader title="New Group Type" parent="Contacts" />

      <div className="col-lg-3"></div>
      <div className="col-lg-6">
        <div className="card">
          <div className="card-body">
            <div className="card m-3">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-row">
                    <div className="col-3">
                      <label htmlFor="exampleFormControlInput1">
                        Type Name:<span className="font-danger">*</span>
                      </label>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <input
                          name="name"
                          type="text"
                          {...register("name")}
                          className={`form-control ${
                            errors.name ? "is-invalid" : ""
                          }`}
                        />
                        <div className="invalid-feedback">
                          {errors.name?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-3">
                      <label htmlFor="exampleFormControlInput1">
                        Description:<span className="font-danger">*</span>
                      </label>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <textarea
                          name="description"
                          type="text"
                          {...register("description")}
                          className={`form-control ${
                            errors.description ? "is-invalid" : ""
                          }`}
                        ></textarea>
                        <div className="invalid-feedback">
                          {errors.description?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                

                  <div className="form-row">
                    <div className="col-9">
                      <div className="form-group  pull-right">
                        <button type="submit" className="btn btn-primary mr-1">
                          Submit
                        </button>

                        <button
                          type="button"
                          onClick={goBack}
                          className="btn btn-danger"
                        >
                          {" "}
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3"></div>
    </Fragment>
  );
};
export default GroupTypeForm;
