import * as Yup from "yup";
export var validationSchema = Yup.object().shape({
  payee: Yup.string().required("Payee details are required"),
  // phone: Yup.string().required("Phone is required"),
  amount: Yup.string().required("Amount is required"),
  account: Yup.string().required("Account is required"),
  paymentFor: Yup.string().required("This field is required"),
  mode: Yup.string().required("Channel is required"),

  // isProject: Yup.string().required("Field is required"),
  // isMpesa: Yup.string().required("Field is required"),
  reference: Yup.string()
    .required("Reference is required")
    .min(2, "Reference must be at least 2 characters"),
  notes: Yup.string()
    .required("Notes are required")
    .min(2, "Notes must be at least 2 characters"),
  dateOfTransaction: Yup.string()
    .required("Date of Transaction is required")
    .matches(
      /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
      "Date of transaction must be a valid date in the format YYYY-MM-DD"
    ),
});
