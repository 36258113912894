import React, { Fragment, useState, useEffect, useCallback } from "react";

import { Star, Search, PlusCircle } from "react-feather";
import { MENUITEMS } from "../../components/common/sidebar-component/menu";
import { Link } from "react-router-dom";
import { Tooltip } from "reactstrap";

const ListActionPanel = (props) => {
  console.log(props.addButtonLink);
  return (
    <Fragment>
      <div className="col-sm-4 float-right">
        <div className="job-filter">
          <div className="faq-form">
            <input
              className="form-control"
              type="text"
              placeholder="type text to search.."
              value={props.value}
              onChange={(event) => props.serchText(event.target.value)}
            />
            <Search className="search-icon" onClick={props.onSearch} />
          </div>
        </div>
      </div>
      <div className="col-sm-2 float-right">
        {props.addButtonName && (
          <Link to={props.addButtonLink}>
            <button className="btn btn-primary" type="button">
              {props.addButtonName}
            </button>
          </Link>
        )}
      </div>
    </Fragment>
  );
};

export default ListActionPanel;
