import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PageHeader from "../../common/pageheader";
import axios from "../../../services/axios";
import Loader from "../../common/loader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import SweetAlert from "sweetalert2";

import { validationSchema } from "./validation_schema";

const genderItems = [
  { id: "Male", title: "Male" },
  { id: "Female", title: "Female" },
  { id: "Other", title: "Other" },
];

const ContactForm = (props) => {
  let history = useHistory();
  const formOptions = { resolver: yupResolver(validationSchema) };
  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;
  const currUser = JSON.parse(localStorage.getItem("c360-user"));
  const goBack = () => {
    history.push("/contacts/default");
  };
  const onSubmit = (data) => {
    if (!currUser || !currUser.user || !currUser.access_token) {
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }

    axios
      .post("/member?tenantid=" + currUser.user.Partner.identifier, data)
      .then((res) => {
        console.log(res);
        SweetAlert.fire({
          title: "Awesome!",
          text: "New contact saved successfully!",
          icon: "success",
        });

        // window.location.href = `${process.env.PUBLIC_URL}/contacts/default`;
        history.push("/contacts/default");
      })
      .catch((error) => {
        if (error.response) {
          SweetAlert.fire({
            title: "Error!",
            text: error.response.data.status_msg,
            icon: "error",
          });
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          SweetAlert.fire({
            title: "Error!",
            text: "The request was made but no response was received",
            icon: "error",
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          SweetAlert.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
          });
        }
      });

    // console.log('testing');
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
    return false;
  };

  return (
    <Fragment>
      <PageHeader title="New Contact" parent="Contacts" />

      <div className="col-lg-3"></div>
      <div className="col-lg-6">
        <div className="card">
          <div className="card-body">
            <div className="card m-3">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-row">
                    <div className="col-3">
                      <label htmlFor="exampleFormControlInput1">
                        FirstName:<span className="font-danger">*</span>
                      </label>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <input
                          name="firstName"
                          type="text"
                          {...register("firstName")}
                          className={`form-control ${
                            errors.firstName ? "is-invalid" : ""
                          }`}
                        />
                        <div className="invalid-feedback">
                          {errors.firstName?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-3">
                      <label htmlFor="exampleFormControlInput1">
                        Last Name:<span className="font-danger">*</span>
                      </label>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <input
                          name="lastName"
                          type="text"
                          {...register("lastName")}
                          className={`form-control ${
                            errors.lastName ? "is-invalid" : ""
                          }`}
                        />
                        <div className="invalid-feedback">
                          {errors.lastName?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-3">
                      <label htmlFor="exampleFormControlInput1">
                        Gender:<span className="font-danger">*</span>
                      </label>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <select
                          name="title"
                          {...register("gender")}
                          className={`form-control ${
                            errors.gender ? "is-invalid" : ""
                          }`}
                        >
                          <option value=""></option>
                          <option value="M">Male</option>
                          <option value="F">Female</option>
                          <option value="O">Other</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  {/* <div className="form-row">
                        <div className="col-3">
                        <label htmlFor="exampleFormControlInput1" >Date of birth:<span className="font-danger">*</span></label>
                               
                            </div>
                            <div className="col-6">
                            <div className="form-group">
                                <input name="dob" type="date" {...register('dob')} className={`form-control ${errors.dob ? 'is-invalid' : ''}`} />
                                <div className="invalid-feedback">{errors.dob?.message}</div>
                            </div>
                        </div>
                    </div> */}

                  <div className="form-row">
                    <div className="col-3">
                      <label htmlFor="exampleFormControlInput1">
                        Mobile:<span className="font-danger">*</span>
                      </label>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <input
                          name="mobile"
                          type="text"
                          {...register("mobile")}
                          className={`form-control ${
                            errors.mobile ? "is-invalid" : ""
                          }`}
                        />
                        <div className="invalid-feedback">
                          {errors.mobile?.message}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="form-row">
                    <div className="col-3">
                      <label htmlFor="exampleFormControlInput1">
                        Email:<span className="font-danger">*</span>
                      </label>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <input
                          name="email"
                          type="text"
                          {...register("email")}
                          className={`form-control ${
                            errors.email ? "is-invalid" : ""
                          }`}
                        />
                        <div className="invalid-feedback">
                          {errors.email?.message}
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="form-row">
                    <div className="col-3">
                      <label htmlFor="exampleFormControlInput1">
                        Other Details:<span className="font-danger">*</span>
                      </label>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <input
                          name="residence"
                          type="text"
                          {...register("residence")}
                          className={`form-control ${
                            errors.residence ? "is-invalid" : ""
                          }`}
                        />
                        <div className="invalid-feedback">
                          {errors.residence?.message}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 
                    <div className="form-row">
                        <div className="col-3">
                        <label htmlFor="exampleFormControlInput1" >Password:<span className="font-danger">*</span></label>
                               
                            </div>
                            <div className="col-6">
                            <div className="form-group">
                            <input name="password" type="password" {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.password?.message}</div>
                            </div>
                        </div>
                    </div>


                    <div className="form-row">
                        <div className="col-3">
                        <label htmlFor="exampleFormControlInput1" >Password:<span className="font-danger">*</span></label>
                               
                            </div>
                            <div className="col-6">
                            <div className="form-group">
                            <input name="confirmPassword" type="password" {...register('confirmPassword')} className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.confirmPassword?.message}</div>
                            </div>
                        </div>
                    </div> */}

                  {/* <div className="form-row">
                        <div className="col-3">
                        <label htmlFor="exampleFormControlInput1" >Accept terms:<span className="font-danger">*</span></label>
                               
                            </div>
                            <div className="col-6">
                            <div className="form-group">
                            <input name="acceptTerms" type="checkbox" {...register('acceptTerms')} id="acceptTerms" className={` ${errors.acceptTerms ? 'is-invalid' : ''}`} />
                       
                           <div className="invalid-feedback">{errors.acceptTerms?.message}</div>
                            </div>
                        </div>
                    </div> */}

                  <div className="form-row">
                    <div className="col-9">
                      <div className="form-group  pull-right">
                        <button type="submit" className="btn btn-primary mr-1">
                          Submit
                        </button>
                        {/* <button
                          type="button"
                          onClick={() => reset()}
                          className="btn btn-secondary"
                        >
                          Reset
                        </button> */}

                        <button
                          type="button"
                          onClick={goBack}
                          className="btn btn-danger"
                        >
                          {" "}
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3"></div>
    </Fragment>
  );
};
export default ContactForm;
