import React, { Fragment } from "react";
import { Home } from "react-feather";
import { Link } from "react-router-dom";
import ListActionPanel from "./listActionPanel";

const PageHeader = (props) => {
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="page-header">
          <div className="row">
            <div className="col">
              <div className="page-header-left">
                <h3>{props.title}</h3>
                <ol className="breadcrumb pull-right">
                  <li className="breadcrumb-item">
                    <Link to={`${process.env.PUBLIC_URL}/dashboard/default`}>
                      <Home />
                    </Link>
                  </li>
                  <li className="breadcrumb-item">{props.parent}</li>
                  <li className="breadcrumb-item active">{props.title}</li>
                </ol>
              </div>
            </div>
            {/* <!-- Bookmark Start--> */}
            {props.onSearch ? (
              <ListActionPanel
                onSearch={props.onSearch}
                serchText={props.serchText}
                addButtonLink = {props.addButtonLink}
                addButtonName = {props.addButtonName}
              />
            ) : (
              ""
            )}
            {/* <!-- Bookmark Ends--> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PageHeader;
