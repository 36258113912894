import React, { Fragment, useState, useEffect } from "react";
import PageHeader from "../../common/pageheader";
import PaginationComponent from "../../common/pagination";
import axios from "../../../services/axios";
import { Edit } from "react-feather";
import { Link } from "react-router-dom";
import SimpleLoader from "react-simple-dots-loader";

const Contacts = () => {
  const [contacts_list, setContactList] = useState([]);
  const [perPage, setPerPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    getData();
  }, []);

  //Fetch data from API endpoint
  const getData = async () => {
    setLoading(true);
    let currUser = JSON.parse(localStorage.getItem("c360-user"));
    let today = new Date();
    let searchdate =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      (today.getDate() + 1);

    if (!currUser || !currUser.user || !currUser.access_token) {
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    const params = {
      tenantid: currUser.user.Partner.identifier,
      searchKey: searchKey,
      action: "display",
      page: currentPage,
      pagesize: perPage,
      startdate: "2020-01-01",
      enddate: searchdate,
    };

    let response = await axios.get("/member", { params });
    console.log(response);
    setContactList(response.data.members);
    setTotalCount(response.data.total);
    setLoading(false);
  };

  // Function to load a specific page of data from API
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    // console.log(e);
    setContactList([]);
    setCurrentPage(e);
    getData();
  };

  // Function to perform search based on provided searchKey
  const handleSearch = () => {
    console.log(searchKey);
    console.log("searchKey");
    setContactList([]);
    getData();
  };

  // Function to perform redirect to edit page for the record. Notice the record is stord in localStorage
  const onClickHandler = (e) => {
    const member_id = e.target.getAttribute("data-item");
    const member = contacts_list.find((element) => element.id == member_id);
    localStorage.setItem("recordForEdit", JSON.stringify(member));
  };

  return (
    <Fragment>
      <PageHeader
        title="All Contacts"
        parent="Contacts"
        onSearch={handleSearch}
        serchText={setSearchKey}
        addButtonLink="/contacts/new"
        addButtonName="New Contact"
      />

      {contacts_list ? (
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-xl-12 xl-100">
                  <div className="user-status table-responsive">
                    <table className="table table-bordernone ">
                      <thead>
                        <tr>
                          <th scope="col">{"First Name"}</th>
                          <th scope="col">{"Last Name"}</th>
                          <th scope="col">{"Gender"}</th>
                          <th scope="col">{"Mobile"}</th>
                          {/* <th scope="col">{"Email"}</th> */}
                          <th scope="col">{"Date Added"}</th>
                          <th scope="col">{"Edit"}</th>
                        </tr>
                      </thead>

                      {loading && (
                        <tbody>
                          <tr>
                            <td colSpan="8">
                              <div className="row mb-3"></div>

                              <div className="row mb-3">
                                <span className="">
                                  &nbsp;&nbsp;&nbsp; Loading Data{" "}
                                  <SimpleLoader color="blue" />
                                </span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      )}
                      <tbody>
                        {contacts_list.map((data) => (
                          <tr key={data.id}>
                            <td>{data.firstName}</td>
                            <td>{data.lastName}</td>
                            <td>{data.gender}</td>
                            <td>{data.phone}</td>
                            {/* <td>{data.email}</td> */}
                            <td className="digits">{data.createdAt}</td>
                            <td>
                              <Link to={"/contacts/edit"}>
                                <button
                                  className="btn btn-secondary btn-xs"
                                  data-item={data.id}
                                  onClick={onClickHandler}
                                >
                                  Edit{" "}
                                  <Edit
                                    data-item={data.id}
                                    color="white"
                                    size={18}
                                  />
                                </button>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <PaginationComponent
                  activePage={currentPage}
                  itemsCountPerPage={perPage}
                  totalItemsCount={totalCount}
                  onChange={handlePageClick}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default Contacts;
