import React, { useEffect, Fragment } from "react";
import ReactDOM from "react-dom";
import jwt_decode from "jwt-decode";
import "./index.scss";

//un-comment this auth by using firebase only
// import app from './data/base';
// import {useState} from 'react';

import {
  BrowserRouter,
  HashRouter,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import * as serviceWorker from "./serviceWorker";

import Signin from "./auth/signin";

// ** Import custom components for redux**
import { Provider } from "react-redux";
import store from "./store/index";
import App from "./components/app";

// Import custom Components

//Contacts
import Default from "./components/dashboard/default";
import Etracker from "./components/dashboard/etracker";
import Contacts from "./components/contacts/contacts/Contacts";
import ContactForm from "./components/contacts/contacts/ContactForm";
import ContactEdit from "./components/contacts/contacts/ContactEdit";

//Groups
import Groups from "./components/contacts/contact_groups/Groups";
import GroupForm from "./components/contacts/contact_groups/GroupForm";
import GroupEdit from "./components/contacts/contact_groups/GroupEdit";
// import Groups from './components/contacts/Groups';
// import grouptypes from './components/contacts/GroupTypes';

import GroupMembers from "./components/contacts/contact_groups/GroupMembers";
import GroupMembersForm from "./components/contacts/contact_groups/GroupMembersForm";

import GroupTypes from "./components/contacts/group_types/GroupTypes";
import GroupTypeForm from "./components/contacts/group_types/GroupTypeForm";
import GroupTypeEdit from "./components/contacts/group_types/GroupTypeEdit";
//Finance
import Income from "./components/finance/income/Income";
import IncomeForm from "./components/finance/income/IncomeForm";
import IncomeEdit from "./components/finance/income/IncomeEdit";

import Mpesa from "./components/finance/mpesa/Mpesa";
import AllocateAccounts from "./components/finance/mpesa/AllocateAccounts";

import Account from "./components/finance/accounts/Accounts";
import EtrackerAccount from "./components/finance/accounts/EtrackerAccounts";
import AccountForm from "./components/finance/accounts/AccountForm";

import Statement from "./components/finance/statements/Statement";
import Budget from "./components/finance/budgets/Budget";
// import AccountEdit from './components/finance/income/AccountEdit';

import Expenses from "./components/finance/expenses/Expenses";
import ExpenseForm from "./components/finance/expenses/ExpenseForm";
import ExpenseEdit from "./components/finance/expenses/ExpenseEdit";

//Groups
import Meetings from "./components/meetings/meetings/Meetings";
import MeetingForm from "./components/meetings/meetings/MeetingForm";
// import MeetingEdit from "./components/meetings/meetings/MeetingsEdit";

import MeetingTypes from "./components/meetings/meeting_types/MeetingTypes";
import MeetingTypeForm from "./components/meetings/meeting_types/MeetingTypeForm";
import MeetingTypeEdit from "./components/meetings/meeting_types/MeetingTypeEdit";

//Messaging
import Messaging from "./components/messaging/messaging/Messaging";
import MessagingForm from "./components/messaging/messaging/MessagingForm";
import UploadMessagesForm from "./components/messaging/messaging/UploadMessagesForm";
import MessagingConfig from "./components/messaging/config/MessagingConfig";
import MessagingConfigForm from "./components/messaging/config/MessagingConfigForm";
import MessagingConfigEdit from "./components/messaging/config/MessagingConfigEdit";
// import MeetingEdit from "./components/meetings/meetings/MeetingsEdit";
import FailedMessages from "./components/messaging/messaging/FailedMessages";
import UploadedFiles from "./components/messaging/messaging/UploadedFiles";

//config data
import configDB from "./data/customizer/config";
import { Group } from "./constant";

require("dotenv").config();

//firebase Auth only then un-comment this current User code
function Root() {
  const abortController = new AbortController();
  // const [currentUser, setCurrentUser] = useState(false);

  let currentUser = JSON.parse(localStorage.getItem("c360-user"));
  let expired = false;

  let decodedToken = currentUser?.access_token
    ? jwt_decode(currentUser.access_token)
    : "";
  console.log("Decoded Token", decodedToken);
  let currentDate = new Date();

  // JWT exp is in seconds
  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    expired = true;
  }

  console.log(currentUser);

  useEffect(() => {
    const layout =
      localStorage.getItem("layout_version") ||
      configDB.data.color.layout_version;
    // app.auth().onAuthStateChanged(setCurrentUser);

    document.body.classList.add(layout);

    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;

    return function cleanup() {
      abortController.abort();
    };
  }, [abortController]);

  return (
    <div className="App">
      <Provider store={store}>
        <HashRouter basename={`/`}>
          <ScrollContext>
            <Switch>
              <Route
                path={`${process.env.PUBLIC_URL}/login`}
                component={Signin}
              />

              {/* NOTE :- If u want login with firebase only then uncomment this currentUser condition*/}
              {currentUser &&
              currentUser.user &&
              currentUser.access_token &&
              !expired ? (
                <Fragment>
                  <App>
                    {/* dashboard menu */ console.log(process.env.PUBLIC_URL)}
                    <Route
                      exact
                      path={"/"}
                      render={() => {
                        return (
                          <Redirect
                            to={`${process.env.PUBLIC_URL}/dashboard/home`}
                          />
                        );
                      }}
                    />
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={Default} /> */}
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/dashboard/home`}
                      component={Default}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/dashboard/etracker`}
                      component={Etracker}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/contacts/default`}
                      component={Contacts}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/contacts/new`}
                      component={ContactForm}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/contacts/edit`}
                      render={(props) => <ContactEdit {...props} />}
                    />
                    {/* <Route path="/test/new" render={(props) => <NewTestComp {...props}/>}/> */}
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/contacts/groups/`}
                      component={Groups}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/contacts/groups/new`}
                      component={GroupForm}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/contacts/groups/edit`}
                      render={(props) => <GroupEdit {...props} />}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/contacts/groups/members`}
                      component={GroupMembers}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/contacts/groupmembers/edit`}
                      component={GroupMembersForm}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/contacts/grouptypes`}
                      component={GroupTypes}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/contacts/grouptypes/new`}
                      component={GroupTypeForm}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/contacts/grouptypes/edit`}
                      render={(props) => <GroupTypeEdit {...props} />}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/messaging/messages`}
                      component={Messaging}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/messaging/messages/new`}
                      component={MessagingForm}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/messaging/uploaded_files`}
                      component={UploadedFiles}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/messaging/uploaded_files/new`}
                      component={UploadMessagesForm}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/messaging/failed_messages`}
                      component={FailedMessages}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/messaging/config`}
                      component={MessagingConfig}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/messaging/config/edit`}
                      component={MessagingConfigEdit}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/messaging/config/new`}
                      component={MessagingConfigForm}
                    />
                  </App>
                </Fragment>
              ) : (
                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
              )}
            </Switch>
          </ScrollContext>
        </HashRouter>
      </Provider>
    </div>
  );
}

ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.unregister();
