import React, { Fragment, useState, useEffect } from "react";
import PageHeader from "../../common/pageheader";
import PaginationComponent from "../../common/pagination";
import axios from "../../../services/axios";
import TableLoader from "../../common/table_loader";
import { Edit } from "react-feather";
// import PlaceholderLoading from "react-placeholder-loading"
import SimpleLoader from 'react-simple-dots-loader'
import SweetAlert from "sweetalert2";

const FailedMessages = () => {
  const [messages_list, setMessageList] = useState([]);
  const [perPage, setPerPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  let currUser = JSON.parse(localStorage.getItem("c360-user"));

  useEffect(() => {
    getData();
  }, []);

  //Fetch data from API endpoint
  const getData = async () => {
    setLoading(true);
    
    let today = new Date();
    let searchdate =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    if (!currUser || !currUser.user || !currUser.access_token) {
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    const params = {
      tenantid: currUser.user.Partner.identifier,
      searchKey: searchKey,
      action: "display",
      page: currentPage,
      pagesize: perPage,
      startdate: "2020-01-01",
      enddate: searchdate,
    };

    let response = await axios.get("/notification/2", { params });
    console.log(response);
    setMessageList(response.data.notifications);
    setTotalCount(response.data.total);
    setLoading(false);
  };

  // Function to load a specific page of data from API
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    // console.log(e);
    setMessageList([]);
    setCurrentPage(e);
    getData();
  };

   // Function to perform search based on provided searchKey
  const handleSearch = () => {
    console.log(searchKey);
    console.log("searchKey");
    setMessageList([]);
    getData();
  };

   // Function to perform redirect to edit page for the record. Notice the record is stord in localStorage
  const onClickHandler = (e) => {
    const message_id = e.target.getAttribute("data-item");
    const message = messages_list.find((element) => element.id == message_id);

    let formData = message

  axios
    .put("/notification/"+message_id+"?tenantid=" + currUser.user.Partner.identifier,formData )
    .then((res) => {
      console.log(res);
      SweetAlert.fire({
        title: "Awesome!",
        text: "Message initiated successfully!",
        icon: "success",
      });
      getData();
     // window.location.href = `${process.env.PUBLIC_URL}/messaging/failed_messages`;
     // history.push("/messaging/messages");
    })
    .catch((error) => {
      if (error.response) {
        SweetAlert.fire({
          title: "Error!",
          text: error.response.data.status_msg,
          icon: "error",
        });
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
        SweetAlert.fire({
          title: "Error!",
          text: "The request was made but no response was received",
          icon: "error",
        });
      } else {
        // Something happened in setting up the request that triggered an Error
        SweetAlert.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
        });
      }
    });
  };

  return (
    <Fragment>
      <PageHeader
        title="Failed Messages"
        parent="Messages"
        onSearch={handleSearch}
        serchText={setSearchKey}
        // addButtonLink = "/messaging/messages/new"
        // addButtonName = "New Message"
      />

      {messages_list ? (
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-xl-12 xl-100">
                  <div className="user-status table-responsive">
                    <table className="table table-bordernone ">
                      <thead>
                        <tr>
                          <th scope="col">{"Recipient"}</th>
                          <th scope="col">{"Type"}</th>
                          <th scope="col">{"Category"}</th>
                          <th scope="col">{"Message"}</th>
                          <th scope="col">{"Status"}</th>
                          <th scope="col">{"Date Sent"}</th>
                          
                        </tr>
                      </thead>
                     
                       
                        {loading && (
                             <tbody>
                           <tr>
                          <td colSpan="8">
                          <div className="row mb-3"></div>
                          
                            <div className="row mb-3">
                             <span className="">&nbsp;&nbsp;&nbsp; Loading Data <SimpleLoader color="blue" /></span>
                            </div>
                          </td></tr>
                          </tbody>
                          
                        )}
                         <tbody>
                        {messages_list.map((data) => (
                          <tr key={data.id}>
                            <td>{data.recipient}</td>
                            <td>{data.type}</td>
                            <td>{data.category}</td>
                            <td>{(data.message.substring(0,40))+"..."}</td>
                            <td>{data.status === 1 ? "Sent" : "Failed"}</td>
                            <td>{data.sentDate}</td> 
                            
                            <td>
                              <button
                                className="btn btn-secondary btn-xs"
                                data-item={data.id}
                                onClick={onClickHandler}
                              >
                                Resend{" "}
                                {/* <Edit
                                  data-item={data.id}
                                  color="white"
                                  size={18}
                                /> */}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <PaginationComponent
                  activePage={currentPage}
                  itemsCountPerPage={perPage}
                  totalItemsCount={totalCount}
                  onChange={handlePageClick}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default FailedMessages;
