import React, { Fragment, useState, useEffect } from "react";
import PageHeader from "../../common/pageheader";
import PaginationComponent from "../../common/pagination";
import axios from "../../../services/axios";
// import TableLoader from "../common/table_loader";
import { Edit,Eye } from "react-feather";
import { Link } from "react-router-dom";
import SimpleLoader from 'react-simple-dots-loader'

const Groups = () => {
  const [groups_list, setGroupList] = useState([]);
  const [perPage, setPerPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  let currUser = JSON.parse(localStorage.getItem("c360-user"));

  useEffect(() => {
    getData();
  }, []);

  //Fetch data from API endpoint
  const getData = async () => {
    setLoading(true);
    
    let today = new Date();
    let searchdate =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    if (!currUser || !currUser.user || !currUser.access_token) {
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    const params = {
      tenantid: currUser.user.Partner.identifier,
      searchKey: searchKey,
      action: "display",
      page: currentPage,
      pagesize: perPage,
      startdate: "2020-01-01",
      enddate: searchdate,
    };

    let response = await axios.get("/groups", { params });
    console.log(response);
    setGroupList(response.data.groups);
    setTotalCount(response.data.total);
    setLoading(false);
  };

  // Function to load a specific page of data from API
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    // console.log(e);
    setGroupList([]);
    setCurrentPage(e);
    getData();
  };

   // Function to perform search based on provided searchKey
  const handleSearch = () => {
    console.log(searchKey);
    console.log("searchKey");
    setGroupList([]);
    getData();
  };

   // Function to perform redirect to edit page for the record. Notice the record is stord in localStorage
  const onClickHandler = (e) => {
    const group_id = e.target.getAttribute("data-item");
    const group = groups_list.find((element) => element.id == group_id);
    localStorage.setItem("recordForEdit", JSON.stringify(group));
    // window.location.href = `${process.env.PUBLIC_URL}/contacts/groups/edit`;
  };

  const onClickViewHandler = (e) => {
    console.log("Handling");
    console.log(e.target);
    const group_id = e.target.getAttribute("data-item");
    const group = groups_list.find((element) => element.id == group_id);
    localStorage.setItem("recordForEdit", JSON.stringify(group));
    // window.location.href = `${process.env.PUBLIC_URL}/contacts/groups/members`;
  };

  return (
    <Fragment>
      <PageHeader
        title="Groups"
        parent="Contacts"
        onSearch={handleSearch}
        serchText={setSearchKey}
        addButtonLink = "/contacts/groups/new"
        addButtonName = "New Group"
      />

      {groups_list ? (
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-xl-12 xl-100">
                  <div className="user-status table-responsive">
                    <table className="table table-bordernone ">
                      <thead>
                        <tr>
                          <th scope="col">{"Organisation"}</th>
                          <th scope="col">{"Group Name"}</th>
                          <th scope="col">{"Description"}</th>
                          <th scope="col">{"Date Added"}</th>
                          <th scope="col">{"View Members"}</th>
                          <th scope="col">{"Edit"}</th>
                        </tr>
                      </thead>


                       
                      {loading && (
                             <tbody>
                           <tr>
                          <td colSpan="6">
                          <div className="row mb-3"></div>
                          
                            <div className="row mb-3">
                             <span className="">&nbsp;&nbsp;&nbsp; Loading Data <SimpleLoader color="blue" /></span>
                            </div>
                          </td></tr>
                          </tbody>
                          
                        )}
                         <tbody>
                        {groups_list.map((data) => (
                          <tr key={data.id}>
                            <td>{currUser.user.Partner.name}</td>
                            <td>{data.name}</td>
                            <td>{data.description}</td>
                            <td className="digits">{data.createdAt}</td>
                            <td>
                             
                                  <Link to={'/contacts/groups/members'} data-item={data.id} onClick={onClickViewHandler}>
                               
                                <Eye                                  
                                  color="orange"
                                  size={18}
                                /> Group Members{" "}
                                </Link>
                             
                            </td>
                            <td>
                            <Link to={'/contacts/sgroups/edit'}>
                              <button
                                className="btn btn-secondary btn-xs"
                                data-item={data.id}
                                onClick={onClickHandler}
                              >
                                Edit{" "}
                                <Edit
                                  data-item={data.id}
                                  color="white"
                                  size={18}
                                />
                              </button>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <PaginationComponent
                  activePage={currentPage}
                  itemsCountPerPage={perPage}
                  totalItemsCount={totalCount}
                  onChange={handlePageClick}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default Groups;
