import * as Yup from 'yup';
export var validationSchema = Yup.object().shape({
    
    groupType: Yup.string()
        .required('Gender is required'),    
    name: Yup.string()
        .required('Name is required'), 
    description: Yup.string()
        .required('Description is required')
        .min(2, 'Description must be at least 2 characters'),
    brandName: Yup.string()
        .required('Description is required'),                    
    // password: Yup.string()
    //     .min(6, 'Password must be at least 6 characters')
    //     .required('Password is required'),
    // confirmPassword: Yup.string()
    //     .oneOf([Yup.ref('password'), null], 'Passwords must match')
    //     .required('Confirm Password is required'),
    // acceptTerms: Yup.bool()
    //     .oneOf([true], 'Accept Ts & Cs is required')
});