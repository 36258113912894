import React, { Fragment, useState, useEffect } from "react";
import PageHeader from "../../common/pageheader";
import axios from "../../../services/axios";
import { CheckSquare,ChevronRight,Search, ChevronLeft } from "react-feather";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SweetAlert from "sweetalert2";
import {useHistory} from "react-router-dom";

import { validationSchema } from "./validation_schema";

import PaginationComponent from "../../common/pagination";
// import PlaceholderLoading from "react-placeholder-loading"
import SimpleLoader from 'react-simple-dots-loader'

import MultiSelectListBox from "react-multiselect-listbox";


const GroupMembersForm = (props) => {
  let history = useHistory();
  const recordForEdit = JSON.parse(localStorage.getItem("recordForEdit"));

  const [group_members_list, setGroupMembersList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [contacts_list, setContactList] = useState([]);
  const [perPage, setPerPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [selectedTwo, setSelectedTwo] = useState([]);

 
  const currUser = JSON.parse(localStorage.getItem("c360-user"));

//   const goBack = () => {
//     window.location.href = `${process.env.PUBLIC_URL}/contacts/groups`;
//   };


  useEffect(() => {
    getContacts();
    getGroupMembers();
  }, []);


  const getContacts = async () => {
    setLoading(true);
    let currUser = JSON.parse(localStorage.getItem("c360-user"));
    let today = new Date();
    let searchdate =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    if (!currUser || !currUser.user || !currUser.access_token) {
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    const params = {
      tenantid: currUser.user.Partner.identifier,
      searchKey: searchKey,
      action: "display",
      page: currentPage,
      pagesize: perPage,
      startdate: "2020-01-01",
      enddate: searchdate,
    };

    let response = await axios.get("/member", { params });
    let response_data = response.data.members.map(v => ({id:v.id, desc: v.firstName + " "+v.lastName+" "+v.phone}));
    console.log(response_data);
    setContactList(response_data);
    // console.log(contacts_list);
    setTotalCount(response.data.total);
    setLoading(false);
  };

  //Fetch data from API endpoint
  const getGroupMembers = async () => {
    setLoading(true);
    let currUser = JSON.parse(localStorage.getItem("c360-user"));
    let today = new Date();
    let searchdate =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    if (!currUser || !currUser.user || !currUser.access_token) {
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    const params = {
      tenantid: currUser.user.Partner.identifier,
      searchKey: '',
      action: "display",
      page: 1,
      pagesize: 25,
      startdate: "2020-01-01",
      enddate: searchdate,
    };
  //  let response
    let response = await axios.get("/groupmembers/"+recordForEdit.id, { params });
    console.log(response);
    let response_data = response.data.members.map(v => ({id:v.MemberId, desc: v.Member.firstName + " "+v.Member.lastName+" "+v.Member.phone}));
  
    setSelectedTwo(response_data.map((item)=>item.id));
    setGroupMembersList(response_data);
    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault(); 
    // console.log(selectedTwo.join());
    let ids = group_members_list.map((e)=> e.id).join();
    // console.log(ids);
    const data = {
        group:recordForEdit.id,
        selectedRecords: ids
    } 
    console.log(data);
     if (!currUser || !currUser.user || !currUser.access_token) {
       window.location.href = `${process.env.PUBLIC_URL}/login`;
     } 
     axios
       .post("/groupmembers?tenantid=" + currUser.user.Partner.identifier, data)
       .then((res) => {
         SweetAlert.fire({
           title: "Awesome!",
           text: "Group members saved successfully!",
           icon: "success",
         });
 
        //  window.location.href = `${process.env.PUBLIC_URL}/contacts/groups/members`;
         history.push("/contacts/groups/members");
       })
       .catch((error) => {
         if (error.response) {
           SweetAlert.fire({
             title: "Error!",
             text: error.response.data.status_msg,
             icon: "error",
           });
         } else if (error.request) {
           // The request was made but no response was received
           console.log(error.request);
           SweetAlert.fire({
             title: "Error!",
             text: "The request was made but no response was received",
             icon: "error",
           });
         } else {
           // Something happened in setting up the request that triggered an Error
           SweetAlert.fire({
             title: "Error!",
             text: error.message,
             icon: "error",
           });
         }
       });
 
     // console.log('testing');
     // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
     return false;
   };

   const handleSearch = () => {
    console.log(searchKey);
    console.log("searchKey");
    setContactList([]);
    getContacts();
  };

  const addContactToGroup = (e) =>{
    // console.log(e.target);
    const memberId = e.target.getAttribute("data-item");
    const member = contacts_list.find((element) => element.id == memberId);
    console.log(group_members_list);
    console.log(member);
    setGroupMembersList( Array.from(new Set([...group_members_list,member])));
    setSelectedTwo(group_members_list.map((item)=>item.id));
    // const result = Array.from(new Set(ages));
    console.log(selectedTwo);

  }
  const removeContactFromGroup = (e) =>{
    // console.log(e.target);
    const memberId = e.target.getAttribute("data-item");
    const member = group_members_list.find((element) => element.id == memberId);
    console.log(group_members_list);
    console.log(member);

    SweetAlert.fire({
      title: 'Are you sure you want to discard a member from this group?',
   text: 'Member will be removed',
   icon: 'warning',
   showCancelButton: true,
   confirmButtonColor: '#3085d6',
   cancelButtonColor: '#d33', 
   confirmButtonText: 'Yes!'
}).then((result) => {
   if(result.value){
    // this.props.submitUser(this.state)
    setGroupMembersList(group_members_list.filter((e)=> e.id != memberId));
    console.log(group_members_list);
    
  }
})
setSelectedTwo(group_members_list.map((item)=>item.id));
    // if(confirm("Are sure you want to remove this guy?")){
    // setGroupMembersList(group_members_list.filter((e)=> e.id != memberId));
 
    // const result = Array.from(new Set(ages));
    // console.log(group_members_list);

  }

  return (
    // recordForEdit && (
      <Fragment>
        <PageHeader title={"Edit "+recordForEdit.name} parent="Contacts" />

      
      {/* <div className="col-lg-6">
        <div className="card">
          <div className="card-body">
            <div className="card m-3">
              <div className="card-body">

        <div className="container">
      <MultiSelectListBox
        className={"multi-select-custom"}
        overrideStrings={{
          search: "Search...",
          selectAll: "Add All",
          removeAll: "Remove All",
          selectedInfo: "Items selected"
        }}
        sortable={true}
        options={contacts_list}
        textField="desc"
        valueField="id"
        value={selectedTwo}
        rowHeight={35}
        
        onSelect={({ item, sortedList }) => {
          console.log(sortedList);
          setSelectedTwo(sortedList.map(i => i.id));
          console.log(selectedTwo);
        }}
        onRemove={({ item }) => {
          setSelectedTwo([...selectedTwo.filter(i => i !== item.id)]);
        }}
        onSelectAll={selectedItems => {
          const selected = [
            ...selectedTwo,
            ...selectedItems.map(item => item.id)
          ];
          setSelectedTwo(selected);
        }}
        onRemoveAll={() => setSelectedTwo([])}
        onSort={({ sortedList }) =>
          setSelectedTwo([...sortedList.map(i => i.value)])
        }
      />
    </div>
   
    </div> 
    
    </div>
    
         <div className="row mb-3">

            <div className="col-6"><div className="form-group  pull-right">Save Changes</div></div>
                    <div className="col-5">
                      <div className="form-group  pull-right">
                        <button type="text" className="btn btn-primary mr-1" onClick={handleSubmit}>
                          Submit
                        </button>
                       
                       </div> </div>
                       
                       <div className="col-1"></div>
                       </ div>
    
       </div> 
     </div>
   </div> */}
 <div className="col-lg-9">
        <div className="card">
          <div className="card-body">
            <div className="card m-3">
              <div className="card-body"></div>
   
   
<div class="container">
    <br />
	<div class="row mb-3">

        <div class="dual-list list-left col-md-5">
            <div class=" text-right">
                <div class="row">
                    <div class="col-md-12">
                        {/* <div class="input-group">
                            <span class="input-group-addon"><Search /></span>
                            <input type="text" name="SearchDualList" class="form-control" placeholder="search" />
                        </div> */}
                        <div className="input-group">
                          {/* <div className="input-group-prepend"><span className="input-group-text"><Search /></span></div> */}
                          <input className="form-control" type="text" placeholder="Search All Contacts" onChange={(event) => setSearchKey(event.target.value)} />
                          {/* <div className="input-group-prepend" title="select all"><span title="select all" className="input-group-text selector"><CheckSquare /></span></div> */}
                
                          <div className="input-group-prepend" onClick={handleSearch}><span className="input-group-text"><Search /></span></div>
                        </div>
                    </div>
                    {/* <div class="col-md-2">
                        <div class="btn-group">
                            <a class="btn btn-default selector" title="select all"><CheckSquare /></a>
                        </div>
                    </div> */}
                </div>
                <ul class="list-group">
                
                {contacts_list.map((data) => (
                         <li class="list-group-item"  data-item={data.id} onClick={(e)=>addContactToGroup(e)}>{data.desc}</li> 
                          
                          ))}
                    
               
                </ul>
            </div>
        </div>

        <div class="list-arrows col-md-1 text-center">
            <button class="btn btn-default btn-sm move-left">
                {/* <span class="glyphicon glyphicon-chevron-left"></span> */}
                <ChevronRight />
            </button>

            <button class="btn btn-default btn-sm move-right">
                {/* <span class="glyphicon glyphicon-chevron-right"></span> */}
                <ChevronLeft />
            </button>
        </div>

        <div class="dual-list list-right col-md-5">
            <div class="well">
                <div class="row">
                    {/* <div class="col-md-2">
                        <div class="btn-group">
                            {/* <a class="btn btn-default selector" title="select all"><i class="glyphicon glyphicon-unchecked"></i></a> */}
                            {/* <a className="btn btn-info btn-xs selector" title="select all"><CheckSquare /></a> */}
                        {/* </div>
                    </div>  */}
                    <div class="col-md-12">
                        <div class="input-group">
                        {/* <div className="input-group-prepend" title="select all"><span title="select all" className="input-group-text selector"><CheckSquare /></span></div> */}
                        <input className="form-control" type="text" placeholder="Group Members" onClick="" />
                        <div className="input-group-prepend"><span className="input-group-text"><Search /></span></div>
                            
                          
                        </div> 
                    </div>
                </div>
                <ul class="list-group">
                {group_members_list.map((data) => (
                         <li class="list-group-item" data-item={data.id} onClick={(e)=>removeContactFromGroup(e)}>{data.desc}</li> 
                          
                          ))}
                </ul>
            </div>
        </div>

	</div>

  <div className="row mb-3">

<div className="col-6"><div className="form-group  pull-right"></div></div>
        <div className="col-5">
          <div className="form-group  pull-right">
            <button type="text" className="btn btn-primary mr-1" onClick={handleSubmit}>
              Save group changes
            </button>
           
           </div> </div>
           
           <div className="col-1"></div>
           </ div>


</div>
</div>
        </div>

	</div>
</div>

      </Fragment>
    // )
  );
};
export default GroupMembersForm;
