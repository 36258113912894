import * as Yup from 'yup';
export var validationSchema = Yup.object().shape({
    
    meeting_type: Yup.string()
        .required('Meeting type is required'),
    dateOfMeeting: Yup.string()
        .required('Date of Meeting is required')
        .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Date of Meeting must be a valid date in the format YYYY-MM-DD'),
    startTime: Yup.string()
        .required('Start time is required'),
    endTime: Yup.string()
        .required('End time is required'),    
    subject: Yup.string()
        .required('Occasion is required'),
    facilitator: Yup.string()
        .required('Facilitator is required'),
    speaker: Yup.string()
        .required('SPeaker is required'),
    actualAttendance: Yup.string()
        .required('Attendance is required'), 
    expectedAttendance: Yup.string()
        .required('Expected Attendance is required'),    
    totalOffering: Yup.number()
        .positive()
        .required('Total Offering is required'),
    // notes: Yup.string()
    //     .required('You have not entered any notes'),                    
    
});