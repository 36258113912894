import * as Yup from "yup";
export var validationSchema = Yup.object().shape({
  recipient_type: Yup.string(),
  textmessage: Yup.string()
    .required("Message is required")
    .min(2, "Message must be at least 2 characters"),
  group: Yup.string(),
  recipients: Yup.string(),
  recipientsfile: Yup.string(),
  type: Yup.string(),
  category: Yup.string(),
});
